html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#081423 !important;
  color: #838c9e !important;
}

/* p{
  font-size: 16px;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar-custom {
  background-color: #313e4f !important;
}
.navbar-brand {
  font-size: 3em !important;
  
}
.navbar-custom .navbar-brand,
.navbar-custom .navbar-text {
  color: #ffffff  ;
  line-height: 2em;
}
.navbar-custom .navbar-nav .nav-link {
  color: #ffffff;
}
.navbar-custom .nav-item.active .nav-link,
.navbar-custom .nav-item:focus .nav-link,
.navbar-custom .nav-item:hover .nav-link {
  color: #636c7c;
}

.text-diferent{
  font-size: 1.2em;
}
section {
  scroll-behavior: smooth !important;    /* <----- THE SECRET ---- */
  
}
.separator{
  min-height: 4rem;
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

.separator-distin{
  display: block;
  position: relative;
  top: -300px;
  visibility: hidden;
  min-height: 8rem;

}
